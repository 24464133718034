import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router, { RouteConfig } from 'vue-router';

// SPE Begin  clean home component
// const Home = () => import('@/core/home/home.vue');
const HomeExtended = () => import('@/core/home-extended/home-extended.vue');
const Error = () => import('@/core/error/error.vue');
// SPE End clean home component

// SPE Begin
import acl247 from '@/router/acl-247';
import sawmill from '@/router/sawmill';
import stock from '@/router/stock';
import manufacturing from '@/router/manufacturing';
import maintenance from '@/router/cmms-maintenance';
import acl010 from '@/router/acl-010';
import label from '@/router/label';
import sqlQuery from '@/router/setup';
import report from '@/router/setup';
import reportCategory from '@/router/setup';
import reportGroup from '@/router/setup';
import config from '@/router/setup';
import calendar from '@/router/calendar';
import widgetSideBar from '@/router/widget-sidebar';
// SPE End

import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HomeExtended',
      component: HomeExtended
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true }
    },
    // SPE Begin
    ...acl247,
    ...sawmill,
    ...stock,
    ...manufacturing,
    ...maintenance,
    ...acl010,
    ...label,
    ...sqlQuery,
    ...report,
    ...reportCategory,
    ...reportGroup,
    ...config,
    ...calendar,
    ...widgetSideBar,
    // SPE End
    ...admin,
    entities,
    ...pages
  ]
});

export default router;
