import { Authority } from '@/shared/security/authority';

const Calendar = () => import('@/entities/spe/calendar/calendar.vue');
const CalendarUpdate = () => import('@/entities/spe/calendar/calendar-update.vue');
const CalendarEvent = () => import('@/entities/spe/calendar/calendar-event.vue');
const CalendarEventView = () => import('@/entities/spe/go-components/go-gadget-calendar/calendar-event-view.vue');
const CalendarEventUpdate = () => import('@/entities/spe/calendar/calendar-event-update.vue');

export default [
  {
    path: '/spe/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/calendar/new',
    name: 'CalendarCreate',
    component: CalendarUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/calendar/:calendarId/edit',
    name: 'CalendarEdit',
    component: CalendarUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/calendar/:calendarId/events',
    name: 'CalendarEvent',
    component: CalendarEvent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/calendar/events/view',
    name: 'CalendarEventView',
    component: CalendarEventView,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/spe/calendar/:calendarId/events/view',
    name: 'CalendarEventView',
    component: CalendarEventView,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/spe/calendar/:calendarId/duplicate',
    name: 'CalendarDuplicate',
    component: CalendarUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/calendar-event/:calendarEventId/edit',
    name: 'SpeCalendarEventEdit',
    component: CalendarEventUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
];
