import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const TSOrder = () => import('@/entities/ts-order/ts-order.vue');
const TSOrderUpdate = () => import('@/entities/ts-order/ts-order-update.vue');
const TSOrderDetails = () => import('@/entities/ts-order/ts-order-details.vue');

const ACL247WIP = () => import('@/entities/acl-247-wip/acl-247-wip.vue');
const ACL247WIPUpdate = () => import('@/entities/acl-247-wip/acl-247-wip-update.vue');
const ACL247WIPDetails = () => import('@/entities/acl-247-wip/acl-247-wip-details.vue');

const Event = () => import('@/entities/event/event.vue');
const EventUpdate = () => import('@/entities/event/event-update.vue');
const EventDetails = () => import('@/entities/event/event-details.vue');

const MaintenanceHeader = () => import('@/entities/maintenance-header/maintenance-header.vue');
const MaintenanceHeaderUpdate = () => import('@/entities/maintenance-header/maintenance-header-update.vue');
const MaintenanceHeaderDetails = () => import('@/entities/maintenance-header/maintenance-header-details.vue');

const MaintenanceLine = () => import('@/entities/maintenance-line/maintenance-line.vue');
const MaintenanceLineUpdate = () => import('@/entities/maintenance-line/maintenance-line-update.vue');
const MaintenanceLineDetails = () => import('@/entities/maintenance-line/maintenance-line-details.vue');

const ImosScmPartRotationHeader = () => import('@/entities/imos-scm-part-rotation-header/imos-scm-part-rotation-header.vue');
const ImosScmPartRotationHeaderUpdate = () => import('@/entities/imos-scm-part-rotation-header/imos-scm-part-rotation-header-update.vue');
const ImosScmPartRotationHeaderDetails = () => import('@/entities/imos-scm-part-rotation-header/imos-scm-part-rotation-header-details.vue');

const ImosScmPartRotationLine = () => import('@/entities/imos-scm-part-rotation-line/imos-scm-part-rotation-line.vue');
const ImosScmPartRotationLineUpdate = () => import('@/entities/imos-scm-part-rotation-line/imos-scm-part-rotation-line-update.vue');
const ImosScmPartRotationLineDetails = () => import('@/entities/imos-scm-part-rotation-line/imos-scm-part-rotation-line-details.vue');

const CadErpBinding = () => import('@/entities/cad-erp-binding/cad-erp-binding.vue');
const CadErpBindingUpdate = () => import('@/entities/cad-erp-binding/cad-erp-binding-update.vue');
const CadErpBindingDetails = () => import('@/entities/cad-erp-binding/cad-erp-binding-details.vue');

const SqlQuery = () => import('@/entities/sql-query/sql-query.vue');
const SqlQueryUpdate = () => import('@/entities/sql-query/sql-query-update.vue');
const SqlQueryDetails = () => import('@/entities/sql-query/sql-query-details.vue');

const ReportGroup = () => import('@/entities/report-group/report-group.vue');
const ReportGroupUpdate = () => import('@/entities/report-group/report-group-update.vue');
const ReportGroupDetails = () => import('@/entities/report-group/report-group-details.vue');

const ReportCategory = () => import('@/entities/report-category/report-category.vue');
const ReportCategoryUpdate = () => import('@/entities/report-category/report-category-update.vue');
const ReportCategoryDetails = () => import('@/entities/report-category/report-category-details.vue');

const Report = () => import('@/entities/report/report.vue');
const ReportUpdate = () => import('@/entities/report/report-update.vue');
const ReportDetails = () => import('@/entities/report/report-details.vue');

const Config = () => import('@/entities/config/config.vue');
const ConfigUpdate = () => import('@/entities/config/config-update.vue');
const ConfigDetails = () => import('@/entities/config/config-details.vue');

const Calendar = () => import('@/entities/calendar/calendar.vue');
const CalendarUpdate = () => import('@/entities/calendar/calendar-update.vue');
const CalendarDetails = () => import('@/entities/calendar/calendar-details.vue');

const CalendarEvent = () => import('@/entities/calendar-event/calendar-event.vue');
const CalendarEventUpdate = () => import('@/entities/calendar-event/calendar-event-update.vue');
const CalendarEventDetails = () => import('@/entities/calendar-event/calendar-event-details.vue');

const Society = () => import('@/entities/society/society.vue');
const SocietyUpdate = () => import('@/entities/society/society-update.vue');
const SocietyDetails = () => import('@/entities/society/society-details.vue');

const SocietyEquipment = () => import('@/entities/society-equipment/society-equipment.vue');
const SocietyEquipmentUpdate = () => import('@/entities/society-equipment/society-equipment-update.vue');
const SocietyEquipmentDetails = () => import('@/entities/society-equipment/society-equipment-details.vue');

const Widget = () => import('@/entities/widget/widget.vue');
const WidgetUpdate = () => import('@/entities/widget/widget-update.vue');
const WidgetDetails = () => import('@/entities/widget/widget-details.vue');

const WidgetCategory = () => import('@/entities/widget-category/widget-category.vue');
const WidgetCategoryUpdate = () => import('@/entities/widget-category/widget-category-update.vue');
const WidgetCategoryDetails = () => import('@/entities/widget-category/widget-category-details.vue');

const WidgetGallery = () => import('@/entities/widget-gallery/widget-gallery.vue');
const WidgetGalleryUpdate = () => import('@/entities/widget-gallery/widget-gallery-update.vue');
const WidgetGalleryDetails = () => import('@/entities/widget-gallery/widget-gallery-details.vue');

const WidgetNotificationCenter = () => import('@/entities/widget-notification-center/widget-notification-center.vue');
const WidgetNotificationCenterUpdate = () => import('@/entities/widget-notification-center/widget-notification-center-update.vue');
const WidgetNotificationCenterDetails = () => import('@/entities/widget-notification-center/widget-notification-center-details.vue');

const SocietyGroup = () => import('@/entities/society-group/society-group.vue');
const SocietyGroupUpdate = () => import('@/entities/society-group/society-group-update.vue');
const SocietyGroupDetails = () => import('@/entities/society-group/society-group-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'ts-order',
      name: 'TSOrder',
      component: TSOrder,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ts-order/new',
      name: 'TSOrderCreate',
      component: TSOrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ts-order/:tSOrderId/edit',
      name: 'TSOrderEdit',
      component: TSOrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ts-order/:tSOrderId/view',
      name: 'TSOrderView',
      component: TSOrderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'acl-247-wip',
      name: 'ACL247WIP',
      component: ACL247WIP,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'acl-247-wip/new',
      name: 'ACL247WIPCreate',
      component: ACL247WIPUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'acl-247-wip/:aCL247WIPId/edit',
      name: 'ACL247WIPEdit',
      component: ACL247WIPUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'acl-247-wip/:aCL247WIPId/view',
      name: 'ACL247WIPView',
      component: ACL247WIPDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'event',
      name: 'Event',
      component: Event,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'event/new',
      name: 'EventCreate',
      component: EventUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'event/:eventId/edit',
      name: 'EventEdit',
      component: EventUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'event/:eventId/view',
      name: 'EventView',
      component: EventDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'maintenance-header',
      name: 'MaintenanceHeader',
      component: MaintenanceHeader,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'maintenance-header/new',
      name: 'MaintenanceHeaderCreate',
      component: MaintenanceHeaderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'maintenance-header/:maintenanceHeaderId/edit',
      name: 'MaintenanceHeaderEdit',
      component: MaintenanceHeaderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'maintenance-header/:maintenanceHeaderId/view',
      name: 'MaintenanceHeaderView',
      component: MaintenanceHeaderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'maintenance-line',
      name: 'MaintenanceLine',
      component: MaintenanceLine,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'maintenance-line/new',
      name: 'MaintenanceLineCreate',
      component: MaintenanceLineUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'maintenance-line/:maintenanceLineId/edit',
      name: 'MaintenanceLineEdit',
      component: MaintenanceLineUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'maintenance-line/:maintenanceLineId/view',
      name: 'MaintenanceLineView',
      component: MaintenanceLineDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'imos-scm-part-rotation-header',
      name: 'ImosScmPartRotationHeader',
      component: ImosScmPartRotationHeader,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'imos-scm-part-rotation-header/new',
      name: 'ImosScmPartRotationHeaderCreate',
      component: ImosScmPartRotationHeaderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'imos-scm-part-rotation-header/:imosScmPartRotationHeaderId/edit',
      name: 'ImosScmPartRotationHeaderEdit',
      component: ImosScmPartRotationHeaderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'imos-scm-part-rotation-header/:imosScmPartRotationHeaderId/view',
      name: 'ImosScmPartRotationHeaderView',
      component: ImosScmPartRotationHeaderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'imos-scm-part-rotation-line',
      name: 'ImosScmPartRotationLine',
      component: ImosScmPartRotationLine,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'imos-scm-part-rotation-line/new',
      name: 'ImosScmPartRotationLineCreate',
      component: ImosScmPartRotationLineUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'imos-scm-part-rotation-line/:imosScmPartRotationLineId/edit',
      name: 'ImosScmPartRotationLineEdit',
      component: ImosScmPartRotationLineUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'imos-scm-part-rotation-line/:imosScmPartRotationLineId/view',
      name: 'ImosScmPartRotationLineView',
      component: ImosScmPartRotationLineDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cad-erp-binding',
      name: 'CadErpBinding',
      component: CadErpBinding,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cad-erp-binding/new',
      name: 'CadErpBindingCreate',
      component: CadErpBindingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cad-erp-binding/:cadErpBindingId/edit',
      name: 'CadErpBindingEdit',
      component: CadErpBindingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cad-erp-binding/:cadErpBindingId/view',
      name: 'CadErpBindingView',
      component: CadErpBindingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sql-query',
      name: 'SqlQuery',
      component: SqlQuery,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sql-query/new',
      name: 'SqlQueryCreate',
      component: SqlQueryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sql-query/:sqlQueryId/edit',
      name: 'SqlQueryEdit',
      component: SqlQueryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sql-query/:sqlQueryId/view',
      name: 'SqlQueryView',
      component: SqlQueryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-group',
      name: 'ReportGroup',
      component: ReportGroup,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-group/new',
      name: 'ReportGroupCreate',
      component: ReportGroupUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-group/:reportGroupId/edit',
      name: 'ReportGroupEdit',
      component: ReportGroupUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-group/:reportGroupId/view',
      name: 'ReportGroupView',
      component: ReportGroupDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-category',
      name: 'ReportCategory',
      component: ReportCategory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-category/new',
      name: 'ReportCategoryCreate',
      component: ReportCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-category/:reportCategoryId/edit',
      name: 'ReportCategoryEdit',
      component: ReportCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-category/:reportCategoryId/view',
      name: 'ReportCategoryView',
      component: ReportCategoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report',
      name: 'Report',
      component: Report,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report/new',
      name: 'ReportCreate',
      component: ReportUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report/:reportId/edit',
      name: 'ReportEdit',
      component: ReportUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report/:reportId/view',
      name: 'ReportView',
      component: ReportDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config',
      name: 'Config',
      component: Config,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/new',
      name: 'ConfigCreate',
      component: ConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/:configId/edit',
      name: 'ConfigEdit',
      component: ConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/:configId/view',
      name: 'ConfigView',
      component: ConfigDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'calendar',
      name: 'Calendar',
      component: Calendar,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'calendar/new',
      name: 'CalendarCreate',
      component: CalendarUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'calendar/:calendarId/edit',
      name: 'CalendarEdit',
      component: CalendarUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'calendar/:calendarId/view',
      name: 'CalendarView',
      component: CalendarDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'calendar-event',
      name: 'CalendarEvent',
      component: CalendarEvent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'calendar-event/new',
      name: 'CalendarEventCreate',
      component: CalendarEventUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'calendar-event/:calendarEventId/edit',
      name: 'CalendarEventEdit',
      component: CalendarEventUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'calendar-event/:calendarEventId/view',
      name: 'CalendarEventView',
      component: CalendarEventDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society',
      name: 'Society',
      component: Society,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society/new',
      name: 'SocietyCreate',
      component: SocietyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society/:societyId/edit',
      name: 'SocietyEdit',
      component: SocietyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society/:societyId/view',
      name: 'SocietyView',
      component: SocietyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society-equipment',
      name: 'SocietyEquipment',
      component: SocietyEquipment,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society-equipment/new',
      name: 'SocietyEquipmentCreate',
      component: SocietyEquipmentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society-equipment/:societyEquipmentId/edit',
      name: 'SocietyEquipmentEdit',
      component: SocietyEquipmentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society-equipment/:societyEquipmentId/view',
      name: 'SocietyEquipmentView',
      component: SocietyEquipmentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget',
      name: 'Widget',
      component: Widget,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget/new',
      name: 'WidgetCreate',
      component: WidgetUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget/:widgetId/edit',
      name: 'WidgetEdit',
      component: WidgetUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget/:widgetId/view',
      name: 'WidgetView',
      component: WidgetDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-category',
      name: 'WidgetCategory',
      component: WidgetCategory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-category/new',
      name: 'WidgetCategoryCreate',
      component: WidgetCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-category/:widgetCategoryId/edit',
      name: 'WidgetCategoryEdit',
      component: WidgetCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-category/:widgetCategoryId/view',
      name: 'WidgetCategoryView',
      component: WidgetCategoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-gallery',
      name: 'WidgetGallery',
      component: WidgetGallery,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-gallery/new',
      name: 'WidgetGalleryCreate',
      component: WidgetGalleryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-gallery/:widgetGalleryId/edit',
      name: 'WidgetGalleryEdit',
      component: WidgetGalleryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-gallery/:widgetGalleryId/view',
      name: 'WidgetGalleryView',
      component: WidgetGalleryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-notification-center',
      name: 'WidgetNotificationCenter',
      component: WidgetNotificationCenter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-notification-center/new',
      name: 'WidgetNotificationCenterCreate',
      component: WidgetNotificationCenterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-notification-center/:widgetNotificationCenterId/edit',
      name: 'WidgetNotificationCenterEdit',
      component: WidgetNotificationCenterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'widget-notification-center/:widgetNotificationCenterId/view',
      name: 'WidgetNotificationCenterView',
      component: WidgetNotificationCenterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society-group',
      name: 'SocietyGroup',
      component: SocietyGroup,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society-group/new',
      name: 'SocietyGroupCreate',
      component: SocietyGroupUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society-group/:societyGroupId/edit',
      name: 'SocietyGroupEdit',
      component: SocietyGroupUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'society-group/:societyGroupId/view',
      name: 'SocietyGroupView',
      component: SocietyGroupDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
