import { Authority } from '@/shared/security/authority';

const WidgetSideBar = () => import('@/entities/spe/go-components/widget-sidebar/widget-sidebar.vue');
const GoGadgetCalendar = () => import('@/entities/spe/go-components/go-gadget-calendar/go-gadget-calendar.vue');
const GoGadgetExpandable = () => import('@/entities/spe/go-components/go-gadget-expandable/go-gadget-expandable.vue');
const GoGadgetCalendarEventList = () =>
  import('@/entities/spe/go-components/go-gadget-calendar-event-list/go-gadget-calendar-event-list.vue');
const GoGadgetRedirect = () => import('@/entities/spe/go-components/go-gadget-redirect/go-gadget-redirect.vue');

const Widget = () => import('@/entities/spe/widget/widget.vue');
const WidgetUpdate = () => import('@/entities/spe/widget/widget-update.vue');
const WidgetCategory = () => import('@/entities/spe/widget-category/widget-category.vue');
const WidgetCategoryUpdate = () => import('@/entities/spe/widget-category/widget-category-update.vue');
const WidgetGallery = () => import('@/entities/spe/widget-gallery/widget-gallery.vue');
const WidgetGalleryUpdate = () => import('@/entities/spe/widget-gallery/widget-gallery-update.vue');
const WidgetNotificationCenter = () => import('@/entities/spe/widget-notification-center/widget-notification-center.vue');
const WidgetNotificationCenterUpdate = () => import('@/entities/spe/widget-notification-center/widget-notification-center-update.vue');

const Society = () => import('@/entities/spe/society/society.vue');
const SocietyUpdate = () => import('@/entities/spe/society/society-update.vue');

const SocietyEquipment = () => import('@/entities/spe/society-equipment/society-equipment.vue');
const SocietyEquipmentUpdate = () => import('@/entities/spe/society-equipment/society-equipment-update.vue');

const SocietyGroup = () => import('@/entities/spe/society-group/society-group.vue');
const SocietyGroupUpdate = () => import('@/entities/spe/society-group/society-group-update.vue');

export default [
  //widget sidebar
  {
    path: '/spe/widget-sidebar',
    name: 'WidgetSideBar',
    component: WidgetSideBar,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/spe/go-gadget-calendar',
    name: 'GoGadgetCalendar',
    component: GoGadgetCalendar,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/go-gadget-calendar-event-list',
    name: 'GoGadgetCalendarEventList',
    component: GoGadgetCalendarEventList,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/go-gadget-expandable',
    name: 'GoGadgetExpandable',
    component: GoGadgetExpandable,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/go-gadget-redirect',
    name: 'GoGadgetRedirect',
    component: GoGadgetRedirect,
    meta: { authorities: [Authority.ADMIN] },
  },

  //widget
  {
    path: '/spe/widget',
    name: 'SpeWidget',
    component: Widget,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget/new',
    name: 'SpeWidgetCreate',
    component: WidgetUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget/:widgetId/edit',
    name: 'SpeWidgetEdit',
    component: WidgetUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget/:widgetId/duplicate',
    name: 'SpeWidgetDuplicate',
    component: WidgetUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  //widget category
  {
    path: '/spe/widget-category',
    name: 'SpeWidgetCategory',
    component: WidgetCategory,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-category/new',
    name: 'SpeWidgetCategoryCreate',
    component: WidgetCategoryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-category/:widgetCategoryId/edit',
    name: 'SpeWidgetCategoryEdit',
    component: WidgetCategoryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-category/:widgetCategoryId/duplicate',
    name: 'SpeWidgetCategoryDuplicate',
    component: WidgetCategoryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  //widget-gallery
  {
    path: '/spe/widget-gallery',
    name: 'SpeWidgetGallery',
    component: WidgetGallery,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-gallery/new',
    name: 'SpeWidgetGalleryCreate',
    component: WidgetGalleryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-gallery/:widgetGalleryId/edit',
    name: 'SpeWidgetGalleryEdit',
    component: WidgetGalleryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-gallery/:widgetGalleryId/duplicate',
    name: 'SpeWidgetGalleryDuplicate',
    component: WidgetGalleryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  //widget-notification-center
  {
    path: '/spe/widget-notification-center',
    name: 'SpeWidgetNotificationCenter',
    component: WidgetNotificationCenter,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-notification-center/new',
    name: 'SpeWidgetNotificationCenterCreate',
    component: WidgetNotificationCenterUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-notification-center/:widgetNotificationCenterId/edit',
    name: 'SpeWidgetNotificationCenterEdit',
    component: WidgetNotificationCenterUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/widget-notification-center/:widgetNotificationCenterId/duplicate',
    name: 'SpeWidgetNotificationCenterDuplicate',
    component: WidgetNotificationCenterUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  //society
  {
    path: '/spe/society',
    name: 'SpeSociety',
    component: Society,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society/new',
    name: 'SpeSocietyCreate',
    component: SocietyUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society/:societyId/edit',
    name: 'SpeSocietyEdit',
    component: SocietyUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society/:societyId/duplicate',
    name: 'SpeSocietyDuplicate',
    component: SocietyUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  //society equipment
  {
    path: '/spe/society-equipment',
    name: 'SpeSocietyEquipment',
    component: SocietyEquipment,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society-equipment/new',
    name: 'SpeSocietyEquipmentCreate',
    component: SocietyEquipmentUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society-equipment/:societyEquipmentId/edit',
    name: 'SpeSocietyEquipmentEdit',
    component: SocietyEquipmentUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society-equipment/:societyEquipmentId/duplicate',
    name: 'SpeSocietyEquipmentDuplicate',
    component: SocietyEquipmentUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  //society group
  {
    path: '/spe/society-group',
    name: 'SpeSocietyGroup',
    component: SocietyGroup,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society-group/new',
    name: 'SpeSocietyGroupCreate',
    component: SocietyGroupUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society-group/:societyGroupId/edit',
    name: 'SpeSocietyGroupEdit',
    component: SocietyGroupUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/spe/society-group/:societyGroupId/duplicate',
    name: 'SpeSocietyGroupDuplicate',
    component: SocietyGroupUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
];
